<app-kckdashboard></app-kckdashboard>

<div class="payouts-container">
  <!-- Kitchen Details Section -->
  <div class="kitchen-details" *ngIf="kitchenDetails">
    <h1>{{kitchenDetails.username}} - Payouts</h1>
  </div>

  <!-- Loading state -->
  <div *ngIf="!kitchenDetails" class="loading-state">
    <div class="loader"></div>
    <p>Loading kitchen details...</p>
  </div>

  <div class="filter-section">
    <div class="date-filters">
      <mat-form-field appearance="outline">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fromDate">
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="toPicker" [(ngModel)]="toDate">
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>

        <button mat-raised-button color="primary" (click)="generateRegularPayouts()" class="generate-btn">
          Generate Regular Payouts
        </button>
        <button mat-raised-button color="primary" (click)="generateSubscriptionPayouts()" class="generate-btn">
          Generate Subscription Payouts
        </button>
    </div>
  </div>

  <!-- Regular Orders Section -->
  <div class="orders-section">
    <h2>Regular Orders Payouts</h2>

    <!-- Pending Payments Table -->
    <div class="table-container">
      <h3>Pending Payments</h3>
      <table>
        <thead>
          <tr>
            <th>Payout ID</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Action</th>
            <th>Refresh Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payout of regularOrdersPendingPayouts">
            <td>{{payout.payoutId}}</td>
            <td>{{payout.fromDate | date}}</td>
            <td>{{payout.toDate | date}}</td>
            <td class="amount">{{payout.amount | currency}}</td>
            <td>
              <span class="status-badge pending">{{payout.paymentStatus}}</span>
            </td>
            <td class="action-column">
              <button mat-raised-button color="primary" (click)="checkBeneficiary(payout)">
                Pay Now
              </button>
            </td>
            <td class="refresh-status-column">
              <button mat-icon-button (click)="refreshStatus(payout)">
                <mat-icon>refresh</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Payment History Table -->
    <div class="table-container mt-4">
      <h3>Payment History</h3>
      <table>
        <thead>
          <tr>
            <th>Payout ID</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Amount</th>
            <th>Transfer ID</th>
            <th>UTR</th>
            <th>Processed On</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payout of regularOrdersCompletedPayouts">
            <td>{{payout.payoutId}}</td>
            <td>{{payout.fromDate | date}}</td>
            <td>{{payout.toDate | date}}</td>
            <td class="amount">{{payout.amount | currency}}</td>
            <td>{{payout.transferId}}</td>
            <td>{{payout.utr || 'N/A'}}</td>
            <td>{{payout.processedOn | date:'medium'}}</td>
            <td>
              <span class="status-badge completed">{{payout.paymentStatus}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Subscription Orders Section -->
  <div class="subscription-section">
    <h2>Subscription Orders Payouts</h2>

    <!-- Pending Payments Table -->
    <div class="table-container">
      <h3>Pending Payments</h3>
      <table>
        <thead>
          <tr>
            <th>Payout ID</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payout of subscriptionPendingPayouts">
            <td>{{payout.payoutId}}</td>
            <td>{{payout.fromDate | date}}</td>
            <td>{{payout.toDate | date}}</td>
            <td class="amount">{{payout.amount | currency}}</td>
            <td>
              <span class="status-badge pending">{{payout.paymentStatus}}</span>
            </td>
            <td class="action-column">
              <button mat-raised-button color="primary" (click)="checkBeneficiary(payout)">
                Pay Now
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Payment History Table -->
    <div class="table-container mt-4">
      <h3>Payment History</h3>
      <table>
        <thead>
          <tr>
            <th>Payout ID</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Amount</th>
            <th>Transfer ID</th>
            <th>UTR</th>
            <th>Processed On</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payout of subscriptionCompletedPayouts">
            <td>{{payout.payoutId}}</td>
            <td>{{payout.fromDate | date}}</td>
            <td>{{payout.toDate | date}}</td>
            <td class="amount">{{payout.amount | currency}}</td>
            <td>{{payout.transferId}}</td>
            <td>{{payout.utr || 'N/A'}}</td>
            <td>{{payout.processedOn | date:'medium'}}</td>
            <td>
              <span class="status-badge completed">{{payout.paymentStatus}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
