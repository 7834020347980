<app-kckdashboard></app-kckdashboard>

<div class="main">
  <div class="container analtic">
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <p class="header">
          <b>
            Lunch bell analytics
            <span *ngIf="KCKuserName">of {{ KCKuserName }}</span></b
          >
        </p>
      </div>
      <div class="col-2"></div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <p style="color: #006600; font-size: 25px">Orders overview</p>
    </div>

    <div class="row">
      <div class="col">
        <p class="dateShow">
          <b>Date: {{ currentDate | date : "dd-MM-yyyy" }}</b>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="startDatePicker"
            placeholder="Start Date"
            [(ngModel)]="startDateString"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            [matDatepicker]="endDatePicker"
            placeholder="End Date"
            [(ngModel)]="endDateString"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <button
          style="margin-left: 25px"
          type="button"
          class="btn btn-secondary"
          (click)="searchAnalystics()"
        >
          Search
        </button>

        <!-- <button *ngIf="isSuperAdmin()"
          style="margin-left: 25px"
          type="button"
          class="btn btn-outline-dark"
          (click)="fetchOfferDetails()"
        >
          Search for offers
        </button> -->
      </div>
    </div>
  </div>

  

  <div class="container mb-4 rounded-frame">
    <div class="row mb-3">
      <div class="col">
        <h2 class="section-title">Daily Orders</h2>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-shopping-cart icon text-primary"></i>
            <div class="ml-3">
              <h5 class="card-title">Total number of orders</h5>
              <p class="card-text text-center"><b>{{ UPI }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-credit-card icon text-success"></i>
            <div class="ml-3">
              <h5 class="card-title">UPI payment</h5>
              <p class="card-text text-center">
                <b *ngIf="totalUPIPrice">₹{{ totalUPIPrice }}</b>
          
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-receipt icon text-warning"></i>
            <div class="ml-3">
              <h5 class="card-title">GST amount</h5>
              <p class="card-text text-center"><b *ngIf="totalGSTPrice">₹{{ totalGSTPrice }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-indian-rupee-sign icon text-success"></i>
            <div class="ml-3">
              <h5 class="card-title">Total amount</h5>
              <p class="card-text text-center"><b *ngIf="totalAmount">₹{{ totalAmount }}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-times-circle icon text-danger"></i>
            <div class="ml-3">
              <h5 class="card-title">No of orders cancelled</h5>
              <p class="card-text text-center"><b>{{ orderCancelled }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-gift icon text-info"></i>
            <div class="ml-3">
              <h5 class="card-title">Offers</h5>
              <p class="card-text text-center"><b>{{ offersApplied }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-money-bill-wave icon text-success"></i>
            <div class="ml-3">
              <h5 class="card-title">Offer payment</h5>
              <p class="card-text text-center"><b *ngIf="offerPrice">₹{{ offerPrice }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-utensils icon text-primary"></i>
            <div class="ml-3">
              <h5 class="card-title">Total number of meals</h5>
              <p class="card-text text-center"><b>{{ totalMealCount }}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- New Daily Orders Table Section -->
    <div class="row mt-4" *ngIf="isKitchen() || KCKProfileID">
      <div class="col-md-8 mb-4">
        <div class="card modern-card">
          <div class="card-header bg-light text-white">
            <!-- <p style="color: #000000; font-size: 25px; margin-bottom: 0">
              <u>Details of Subscriptions</u>
            </p> -->
            <h5 class="card-title">Details of Daily Orders</h5>
          </div>
          <div class="card-body">
           
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col" class="text-center" width="10%">#</th>
                  <th scope="col" width="60%">Meal Details</th>
                  <th scope="col" class="text-center" width="30%">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ItemsCount; let i = index">
                  <td class="text-center">{{ i + 1 }}</td>
                  <td>
                    <strong>Meal #{{ item.mealNumber }}</strong><br>
                    <small class="text-muted">{{ item.name }}</small>
                  </td>
                  <td class="text-center"><span class="badge bg-secondary">{{ item.count }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  
  <br />
  <!--subscription details -->
  <div class="container mb-4 rounded-frame">
    <div class="row mb-3">
      <div class="col">
        <h2 class="section-title">Subscriptions</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-user-plus icon text-primary"></i>
            <div class="ml-3">
              <h5 class="card-title">Subscriptions started</h5>
              <p class="card-text text-center"><b>{{ noOfSubscriptions }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-money-bill-wave icon text-success"></i>
            <div class="ml-3">
              <h5 class="card-title">Offer Payment</h5>
              <p class="card-text text-center"><b *ngIf="totalOfferAmount">₹{{ totalOfferAmount }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-receipt icon text-warning"></i>
            <div class="ml-3">
              <h5 class="card-title">GST Amount</h5>
              <p class="card-text text-center"><b *ngIf="totalGSTAmount">₹{{ totalGSTAmount }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card modern-card">
          <div class="card-body d-flex align-items-center">
            <i class="fas fa-indian-rupee-sign icon text-success"></i>
            <div class="ml-3">
              <h5 class="card-title">Total Amount</h5>
              <p class="card-text text-center"><b *ngIf="subscriptionOrdersTotalAmount">₹{{ subscriptionOrdersTotalAmount }}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br>
    <div class="row" *ngIf="isKitchen() || KCKProfileID">
      <div class="col-md-10 mb-4">
        <div class="card">
          <div class="card-header bg-light text-white">
            <h5 class="card-title">Details of Subscriptions</h5>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">Subscription ID</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Meal Details</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subscription of subscriptionDetails">
                    <td>
                      <small class="text-muted">{{ subscription.subscriptionID }}</small>
                    </td>
                    <td>
                      <strong>{{ subscription.userName }}</strong>
                      <br>
                      <small class="text-muted">{{ subscription.address.building }}, {{ subscription.address.street }}</small>
                    </td>
                    <td>
                      <span class="badge bg-info">{{ subscription.subscriptionDays }} Days</span>
                      <br>
                      <small class="text-muted">{{ subscription.remainingDays }} days remaining</small>
                    </td>
                    <td>{{ subscription.subscriptionStartDate | date:'mediumDate' }}</td>
                    <td>
                      <ng-container *ngFor="let item of subscription.orderItems">
                        <strong>Meal #{{ item.mealNumber }}</strong>
                        <br>
                        <small class="text-muted">{{ item.name }}</small>
                      </ng-container>
                    </td>
                    <td>
                      <span [class]="'badge ' + (subscription.subscriptionActive ? 'bg-success' : 'bg-danger')">
                        {{ subscription.subscriptionActive ? 'Active' : 'Inactive' }}
                      </span>
                    </td>
                    <td>
                      <strong *ngIf="subscription.totalPrice">₹{{ subscription.totalPrice }}</strong>
                      <br>
                      <small class="text-muted" *ngIf="subscription.offerPrice > 0">
                        Offer: ₹{{ subscription.offerPrice }}
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isKitchen() || KCKProfileID">
    <!-- New Subscription Meal Counts Table -->
    <div class="row" *ngIf="subscriptionMealCountsByDays">
      <div class="col-md-8 mb-4">
        <div class="card">
          <div class="card-header bg-light text-white">
            <h5 class="card-title">Details of Orders generated from Subscriptions</h5>
          </div>
          <div class="card-body">
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
             
                  <th scope="col" width="60%">Meal Details</th>
                  <th scope="col" class="text-center" width="10%">Duration</th>
                  <th scope="col" class="text-center" width="30%">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let days of ['15', '30']">
                  <ng-container *ngIf="subscriptionMealCountsByDays[days]">
                    <tr *ngFor="let meal of getObjectKeys(subscriptionMealCountsByDays[days])">
                     
                      <td>
                        <strong>Meal #{{ subscriptionMealCountsByDays[days][meal].mealNumber }}</strong><br>
                        <small class="text-muted">{{ subscriptionMealCountsByDays[days][meal].name }}</small>
                      </td>
                      <td> <span  class="badge bg-info">{{ days }} Days</span></td>
                      <td class="text-center"><span >{{ subscriptionMealCountsByDays[days][meal].count }}</span></td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <br />

  <!------------------------->

  <br /><br />
  <!--accepted orders details starts-->
<!-- <div *ngIf="isKitchen() || KCKProfileID">
  <div class="col-6">
    <div class="container mb-2" >
      <div class="row">
        <div class="col">
          <p style="color: #001866; font-size: 25px; margin-bottom: 0">
            <u>Daily Order details</u>
          </p>
        </div>
      </div>
    </div>
    <div class="container" >
      <div class="row">
        <div class="col" >
          <table class="table" style="border: 1px solid #ddd; border-collapse: collapse;">
            <thead>
              <tr>
                <th scope="col" style="border: 1px solid #ddd;">No</th>
                <th scope="col" style="border: 1px solid #ddd;">Meals</th>
                <th scope="col" style="border: 1px solid #ddd;">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of ItemsCount; let i = index">
                <td style="border: 1px solid #ddd;">{{ i + 1 }}</td>
                <td style="border: 1px solid #ddd;">Meal # {{ item.mealNumber }} ({{ item.name }})</td>
                <td style="border: 1px solid #ddd;">{{ item.count }}</td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="container mb-2" >
      <div class="row">
        <div class="col">
          <p style="color: #001866; font-size: 25px; margin-bottom: 0">
            <u>Subscription Based Order details</u>
          </p>
        </div>
      </div>
    </div>
    <div class="container" >
      <div class="row">
        <div class="col" >
          <table class="table" style="border: 1px solid #ddd; border-collapse: collapse;">
            <thead>
              <tr>
                <th scope="col" style="border: 1px solid #ddd;">No</th>
                <th scope="col" style="border: 1px solid #ddd;">Meals</th>
                <th scope="col" style="border: 1px solid #ddd;">Quantity</th>
              </tr>
            </thead>
            <tbody>
            
              <tr *ngFor="let item of subscriptionBasedOrdersItemsCount; let i = index">
                <td style="border: 1px solid #ddd;"><span style="font-weight: bold; color: rgb(38, 163, 30);">{{ i + 1 }}</span></td>
                <td style="border: 1px solid #ddd;"><span style="font-weight: bold; color: rgb(38, 163, 30);">Meal # {{ item.mealNumber }} ({{ item.name }})</span></td>
                <td style="border: 1px solid #ddd;"><span style="font-weight: bold; color: rgb(38, 163, 30);">{{ item.count }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>



  <div *ngIf="isKitchen() || KCKProfileID">

  <div class="col-6">
    <div class="container mb-2" >
      <div class="row">
        <div class="col">
          <p style="color: #001866; font-size: 25px; margin-bottom: 0">
            <u>Subscription details</u>
          </p>
        </div>
      </div>
    </div>
    <div class="container" >
      <div class="row">
        <div class="col" >
          <table class="table" style="border: 1px solid #ddd; border-collapse: collapse;">
            <thead>
              <tr>
                <th scope="col" style="border: 1px solid #ddd;">Subscription type</th>
                <th scope="col" style="border: 1px solid #ddd;">Meal name</th>
                <th scope="col" style="border: 1px solid #ddd;">Quantity</th>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let subscriptionType of ['15', '30']">
                <ng-container *ngIf="subscriptionAnalyticsForKitchen?.subscriptionsByDays[subscriptionType]">
                  <tr *ngFor="let mealName of getObjectKeys(subscriptionAnalyticsForKitchen.subscriptionsByDays[subscriptionType].quantities); let first = first">
                    <td style="border: 1px solid #ddd;" *ngIf="first" [attr.rowspan]="getObjectKeys(subscriptionAnalyticsForKitchen.subscriptionsByDays[subscriptionType].quantities).length">
                      {{ subscriptionType }} days
                    </td>
                    <td style="border: 1px solid #ddd;">{{ mealName }}</td>
                    <td style="border: 1px solid #ddd;">{{ subscriptionAnalyticsForKitchen.subscriptionsByDays[subscriptionType].quantities[mealName] }}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  
</div> -->


