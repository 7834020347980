import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const ADMIN_URL = environment.adminUrl;

interface Kitchen {
  _id: string;
  profileID: string;
  username: string;
  legalEntityName: string;
  bankDetails: {
    bankName: string;
    bankBranch: string;
    IFSCcode: string;
    accountNumber: string;
  };
  pendingAmount: number;
  lastPayout?: Date;
  active: boolean;
}

@Component({
  selector: 'app-payouts',

  templateUrl: './payouts.component.html',
  styleUrl: './payouts.component.css'
})
export class PayoutsComponent implements OnInit {
  searchText: string = '';
  kitchens: Kitchen[] = [];

  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fetchKitchens();
  }

  fetchKitchens() {
    this.http.get<{success: boolean, data: Kitchen[]}>(`${ADMIN_URL}/payouts/listKitchens`)
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.kitchens = response.data.map(kitchen => ({
              ...kitchen,
              pendingAmount: kitchen.pendingAmount || 0,
              lastPayout: kitchen.lastPayout ? new Date(kitchen.lastPayout) : undefined
            }));
          }
        },
        error: (error) => {
          console.error('Error fetching kitchens:', error);
        }
      });
  }

  viewKitchenPayouts(kitchenId: string) {
    this.router.navigate(['/kitchen-payout'], {
      queryParams: {
        kitchenId: kitchenId
      }
    });
  }

  searchKitchens() {
    if (this.searchText.trim()) {
      this.kitchens = this.kitchens.filter(kitchen =>
        kitchen.profileID.toLowerCase().includes(this.searchText.toLowerCase()) ||
        kitchen.username.toLowerCase().includes(this.searchText.toLowerCase()) ||
        kitchen.legalEntityName?.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.fetchKitchens(); // Reset to full list
    }
  }

  resetSearch() {
    this.searchText = '';
    this.fetchKitchens();
  }
}
