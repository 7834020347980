<app-kckdashboard></app-kckdashboard>

<div class="main">
  <!--header -->
  <div class="container">
    <div class="row">
      <div class="col">
        <h3 class="header"><b>Subscription Details</b></h3>
      </div>
    </div>
  </div>
  <!------------>

  <!-- subscription analytics overview-->
  <div class="container">
    <div class="row m-2">
      <p style="color: #006600; font-size: 25px">
        <u>Subscription overview</u>
      </p>
    </div>
    <div class="row m-3">
      <div class="col">
        <div class="card user p-0">
          <div class="card-body">
            <p class="card-title">Total no of subscription</p>
            <p class="card-text" style="text-align: center">
              <b>{{ subscriptionTotalCount }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card active">
          <div class="card-body">
            <p class="card-title">Active subscription</p>
            <p class="card-text" style="text-align: center">
              <b>{{ subscriptionActiveCount }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <!-- <div class="card delete">
          <div class="card-body">
            <p class="card-title">Inactive subscription</p>
            <p class="card-text" style="text-align: center">
              <b>{{subscriptionInactiveCount}}</b>
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
    </div>
  </div>
  <!--------------------------------->

  <div class="container mt-4">
    <div class="row m-2">
      <p style="color: #006600; font-size: 25px">
        <u>Subscription details </u>
      </p>
    </div>
    <div class="row">
      <div class="col-6">
        <button class="btn btn-primary me-2" (click)="loadActiveSubscriptions()">Active Subscriptions</button>
        <button class="btn btn-danger  me-2" (click)="loadInactiveSubscriptions()">Inactive Subscriptions</button>
      
        <button class="btn btn-secondary" (click)="loadExpiredSubscriptions()">Expired Subscriptions</button>
      </div>
    </div>
  </div>

  <!-- New section for active subscriptions -->
<div class="container mt-4" *ngIf="isActiveSubscriptions">
  <div class="row">
    <div class="col">
      <h4 class="text-primary">Active Subscriptions</h4>
      <p>The following subscriptions are currently active, and the generated subscription-based orders will be based on this list.</p>
    </div>
  </div>
</div>

  <div class="container mt-4" *ngIf="!isActiveSubscriptions">
    <div class="row">
      <div class="col">
        <h4 class="text-danger">InActive Subscriptions</h4>
        <p>The following subscriptions are currently Inactive, and the generated subscription-based orders will not be generated for these subscriptions.</p>
      </div>
    </div>
  </div>
  <div class="container mt-4" *ngIf="!isActiveSubscriptions">
    <div class="row">
      <div class="col">
        <h4 class="text-secondary">Expired Subscriptions</h4>
        <p>The following subscriptions are currently expired.</p>
      </div>
    </div>
  </div>

  <!--subscription details table-->
  <div class="container p-4">
    <div class="row">
      <div class="col card">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>

              <th scope="col" class="align-center">SubscriptionID</th>
              <th class="align-center" scope="col">Subscription start date</th>
              <th class="align-center" scope="col">User name</th>
              <th class="align-center" scope="col">Delivery address</th>
              <th class="align-center" scope="col">Subscription days</th>
              <th class="align-center" scope="col">Remaining days</th>

              <!-- <th class="align-center" scope="col">Offer Amount</th>
                <th class="align-center" scope="col">Total Amount</th> -->
              <th class="align-center" scope="col">Subscription Status</th>
              <th class="align-center" scope="col">Show more</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let subscriptionDetails of filteredSubscriptionDetails;
                let i = index
              "
            >
              <th scope="row">{{ i + 1 }}</th>

              <td class="align-center">
                {{ subscriptionDetails.subscriptionID }}
              </td>
              <td class="align-center">
                {{ subscriptionDetails.subscriptionStartDate | date }}
              </td>

              <td class="align-center">
                {{ subscriptionDetails.userName }}
              </td>
              <td class="align-center">
                {{ subscriptionDetails.address.address }}
              </td>
              <td class="align-center">
                {{ subscriptionDetails.subscriptionDays }}
              </td>
              <td class="align-center">
                {{ subscriptionDetails.remainingDays }}
              </td>
              <!-- <td class="align-center">
                  {{subscriptionDetails}}
                </td>

                <td class="align-center">{{subscriptionDetails}}</td>

                <td class="align-center">{{subscriptionDetails}}</td> -->
              <td
                class="align-center"
                [ngClass]="{
                  Active: subscriptionDetails.subscriptionActive,
                  Deactive: !subscriptionDetails.subscriptionActive
                }"
              >
                {{
                  subscriptionDetails.subscriptionActive ? "Active" : "Inactive"
                }}
              </td>
              <td class="align-center">
                <button
                  type="button"
                  class="btn btn-outline-primary me-2"
                  (click)="goToSubscriptionDetails(subscriptionDetails._id)"
                >
                  Details
                </button>
                <button
                  *ngIf="subscriptionDetails.subscriptionActive"
                  type="button"
                  class="btn btn-outline-danger"
                  (click)="deactivateSubscription(subscriptionDetails._id)"   
                >
                  Deactivate
                </button>
                <button
                  *ngIf="!subscriptionDetails.subscriptionActive && subscriptionDetails.remainingDays > 0"
                  type="button"
                  class="btn btn-outline-success"
                  (click)="activateSubscription(subscriptionDetails._id)" 
                >
                  Activate
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!---------------------------------------------------->
</div>
