import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
const ADMIN_URL = environment.adminUrl;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getSubscriptionsListing(): Observable<any[]> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any[]>(`${ADMIN_URL}/subscriptionsListings`, { headers });
  }

  getSubscriptionCounts(): Observable<any> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(`${ADMIN_URL}/subscriptionsTotalCount`, { headers });
  }


  getSubscriptionCountsForKitchen(): Observable<any> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>(`${ADMIN_URL}/subscriptionsTotalCountForKitchen`, { headers });
  }

  getSubscriptionsListingForKitchen(): Observable<any[]> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any[]>(`${ADMIN_URL}/subscriptionsListingsForKitchen`, { headers });
  }
  
  getInactiveSubscriptionsListingForKitchen(): Observable<any[]> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any[]>(`${ADMIN_URL}/inactiveSubscriptionsListingsForKitchen`, { headers });
  }

  getExpiredSubscriptionsListingForKitchen(): Observable<any[]> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any[]>(`${ADMIN_URL}/expiredSubscriptionsListingsForKitchen`, { headers });
  }

  updateSubscriptionStatus(subscriptionId: string, isActive: boolean): Observable<any> {
    const token = this.authService.getToken();
    console.log('the order function is called');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.put(`${ADMIN_URL}/subscriptionActiveStatus/${subscriptionId}`, { subscriptionActive: isActive }, { headers });
  }
}
