import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { KitchenOrdersService } from '../kitchen-order-details/kitchen-orders.service';
import { SubscriptionOrdersService } from '../subscription-orders/subscription-orders.service';

@Component({
  selector: 'app-kckdashboard',
  templateUrl: './kckdashboard.component.html',
  styleUrls: ['./kckdashboard.component.css']
})
export class KckdashboardComponent {

  constructor(private router: Router, private AuthService : AuthService,private kitchenOrdersService: KitchenOrdersService,  private SubscriptionOrdersService: SubscriptionOrdersService) { }
  showDropdown: boolean = false;

  ngOnInit() {
    this.checkLogin();
  }

  checkLogin(): void {
    const isLoggedIn = this.AuthService.isAuthenticated();
    if(isLoggedIn){
      console.log('user is logged in');
    }
    else{
      this.router.navigate(['/login']);
    }
  }

 


  gotokitchen(){
    this.router.navigate(['/centralkitchen']);
  }

  gotoAnalytics() {

    this.router.navigate(['/Kckanalytics']);
  }

  gotouserdetails() {
  
    this.router.navigate(['/userdetails']);
  }

  onLogout(){
    this.AuthService.logOut();
   this.kitchenOrdersService.setOrderDetails(
    null,
    null,
    null
  );
  this.SubscriptionOrdersService.setSubscriptionOrderDetails(
    null,
    null,
    null
  );
  }

  goToPayoutsPage() {
    console.log("Navigating to payouts page");
    // Add your navigation logic here
    this.router.navigate(['/payouts']);

}

  gotoOrderDetails(){
    this.router.navigate(['/kitchen-order-details']);
  }

  goToSubscriptionOrders() {
    this.router.navigate(['/subscriptionOrders'], {});
  }
  goToSubscriptionPage() {
    this.router.navigate(['/subscriptionPage']);
  }
  goToSignup(){
    this.router.navigate(['/signup']);
  }

  addDeliveryPerson(){
    this.router.navigate(['/add-delivery-person']);
  }

  viewDeliveryPersons(){
    this.router.navigate(['/view-delivery-persons']);
  }

  gotoMyprofile(){
    this.router.navigate(['/kitchendetails'], {
      // queryParams: {
      //   dateBaseID: kckID,
      // },
    });
  }

  gotoChangePassword(){
    this.router.navigate(['/passwordChange']);
  }

  gotoNotification(){
    this.router.navigate(['/notification-page']);
  }



  isAdmin(): boolean {
    return this.AuthService.isAuthenticated() && this.AuthService.isAdmin();
  }

  isKitchen(): boolean {
    return this.AuthService.isAuthenticated() && this.AuthService.isKitchen();
  }

  isSuperAdmin(): boolean {
    return this.AuthService.isAuthenticated() && this.AuthService.isSuperAdmin();
  }


  goToAccountDetailsPage(){
    this.router.navigate(['/account-details']);
  }


  goToSettingsPage(){
    this.router.navigate(['/settings']);
  }
}






