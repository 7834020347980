<app-kckdashboard></app-kckdashboard>
<div class="main">
    <div class="container p-3 card cardmain">
      <div class="row">
        <div class="col">
          <p class="card-title" style="text-align: center; margin-bottom: 25px">
            <b>Settings</b>
          </p>
  
          <div class="row mt-2">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Set invoice numbers</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row"><div class="col-6">   <div class="overlay">
                  <div class="modal-content">
                    <div class="modal-body text-left p-3">
                      <div class="form-group m-2">
                        <label for="maximumOrder"
                          >Enter invoice prefix
                        </label>
                        <input
                          type="text"
                          class="form-control mt-2"
                          [(ngModel)]="invoicePrefix"
                          placeholder="Enter invoice prefix."
                          required
                        />
                        <br />

                        <label for="maximumOrder"
                          >Enter invoice suffix
                        </label>
                        <input
                          type="number"
                          class="form-control mt-2"
                          [(ngModel)]="invoiceSuffix"
                          placeholder="Enter invoice suffix."
                          required
                        />
                      </div>

                      <div class="row">
                        <div class="col">
                          <button
                            type="button"
                            style="float: right; width: 100%"
                            class="btn btn-success saveBtn"
                            (click)="saveInvoiceSettings()"
                          >
                            Save Invoice Numbers
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div></div></div>

             
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <!-- <div class="row mt-2">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Set Lunch bell carousel</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row"><div class="col-6">   <div class="overlay">
                  <div class="modal-content">
                    <div class="modal-body text-left p-3">
                      <div class="form-group m-2">
                       
                      </div>

                      <div class="row">
                        <div class="col">
                          <button
                            type="button"
                            style="float: right; width: 100%"
                            class="btn btn-success saveBtn"
                            (click)="saveCarouselImages()"
                          >
                            Save carousel images
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div></div></div>

             
              </mat-expansion-panel>
            </mat-accordion>
          </div> -->

         
          <div class="row mt-2">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Set Pocket mart carousel</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-12">
                    <div class="carousel-images-container">
                      <div class="carousel-images">
                        <div *ngFor="let image of pmCarouselImages; let i = index" class="carousel-image-item">
                          <img [src]="image" alt="Carousel image" class="img-thumbnail">
                          <div class="image-actions">
                            <button class="btn btn-sm btn-primary" (click)="fileInput.click()">Edit</button>
                            <input #fileInput type="file" style="display: none" (change)="onPMFileSelected($event, i)">
                            <button class="btn btn-sm btn-danger" (click)="deletePMCarouselImage(i)">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="pmCarouselImages.length < 7" class="mt-3">
                      <button class="btn btn-success" (click)="newFileInput.click()">
                        Add New Image
                      </button>
                      <input #newFileInput type="file" style="display: none" (change)="onPMFileSelected($event)">
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>


          <div class="row mt-2">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Set Lunch bell carousel</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-6">
                    <div class="overlay">
                      <div class="modal-content">
                        <div class="modal-body text-left p-3">
                          <div class="form-group m-2">
                            <input
                              type="file"
                              name="images"
                              multiple
                              (change)="onLBFileSelected($event)"
                            />
                          </div>
                          <div class="row">
                            <div class="col">
                              <button
                                type="button"
                                style="float: right; width: 100%"
                                class="btn btn-success saveBtn"
                                (click)="confirmUpload('LB')"
                              >
                              Save Lunch bell carousel images
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>


          <div class="row mt-2">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Set quick serve carousel</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-6">
                    <div class="overlay">
                      <div class="modal-content">
                        <div class="modal-body text-left p-3">
                          <div class="form-group m-2">
                            <input
                              type="file"
                              name="images"
                              multiple
                              (change)="onQSFileSelected($event)"
                            />
                          </div>
                          <div class="row">
                            <div class="col">
                              <button
                                type="button"
                                style="float: right; width: 100%"
                                class="btn btn-success saveBtn"
                                (click)="confirmUpload('QS')"
                              >
                                Save Quick Serve carousel images
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
