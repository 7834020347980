<app-kckdashboard></app-kckdashboard>

<div class="main">
  <div class="container m-3">
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <p class="header">
          <b> Daily Orders</b>
        </p>
      </div>
      <div class="col"></div>
      <div class="col mt-2 mb-3">
        <button
          type="button"
          (click)="downloadToday()"
          class="btn btn-outline-dark"
        >
          Download today's order
        </button>

        <!-- <button
          type="button"
          (click)="downloadTodayOrder()"
          class="btn btn-outline-dark"
        >
          Download today's order
        </button>

        <select class="form-select" (change)="onAddressSelected($event)">
          <option value="">All Addresses</option>
          <option value="Pattom">Pattom</option>
          <option value="LMS">LMS</option>
          <option value="Statue">Statue</option>
          <option value="Ayurveda college">Ayurveda college</option>
          <option value="Trivandrum medical college">
            Trivandrum medical college
          </option>
        </select>
      </div> -->
      </div>
    </div>

    <div class="container box" *ngIf="todayOrder">
      <div class="row">
        <div class="col card todayOrder">
          <select class="form-select" (change)="onAddressSelected($event)">
            <option value="">Please select location</option>
           
            <option value="Medical college">
              Medical college
            </option>
            <option value="Pattom">Pattom</option>
            <option value="LMS">LMS</option>
            <option value="Statue">Statue</option>
            <option value="Ayurveda college">Ayurveda college</option>
            <option value="Nalanchira">Nalanchira</option>
            <option value="Civil Station">Civil Station</option>
            <option value="Karamana">Karamana</option>
            <option value="Vazhuthacaud">Vazhuthacaud</option>
            <option value="Jagathy">Jagathy</option>
            <option value="Thampanoor">Thampanoor</option>
            <option value="Poojapura">Poojapura</option>
          </select>

          <div class="row mt-3">
            <div class="col-4">
              <button
                type="button"
                (click)="closeDownload()"
                class="btn btn-secondary"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                style="float: right"
                (click)="downloadTodayOrder()"
                class="btn btn-outline-dark"
              >
                Download orders
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Choose a date"
              [(ngModel)]="selectedDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field *ngIf="isAdmin() || isSuperAdmin()" class="ps-2">
            <input
              matInput
              [matDatepicker]="endDatepicker"
              placeholder="Choose end date"
              [(ngModel)]="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatepicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
          </mat-form-field>

          <button
            *ngIf="isAdmin() || isSuperAdmin()"
            type="button"
            style="margin-left: 15px"
            class="btn btn-success"
            (click)="fetchOrderDetails()"
          >
            Search
          </button>
          <button
            *ngIf="isKitchen()"
            type="button"
            style="margin-left: 15px"
            class="btn btn-success"
            (click)="fetchOrderDetailsForKitchen()"
          >
            Search
          </button>
          <button
            *ngIf="!downloadPDF"
            (click)="promptDownloadOptions()"
            type="button"
            class="btn btn-primary"
            style="margin-left: 15px"
          >
            Download below list
          </button>

          <!---------- subscription details page button---------------->

          <!-- <button
            class="btn btn-secondary"
            type="button"
            style="margin-left: 15px; float: right"
            (click)="goToSubscriptionOrders()"
          >
            Subscription Orders
          </button>
          <button
            (click)="goToSubscriptionPage()"
            type="button"
            class="btn btn-dark"
            style="margin-left: 15px; float: right"
          >
            Subscription details
          </button> -->

          <!------------------------->

          <div
            *ngIf="downloadPDF"
            class="spinner-border text-primary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mb-3">
        <div class="div" style="display: inline-block">
          <h6 style="color: #006600; font-size: 25px; display: inline">
            <u>Order list</u>
          </h6>
          <button
            *ngIf="isKitchen()"
            [disabled]="!disableButton"
            style="display: inline; float: right; margin-bottom: 5px"
            type="button"
            class="btn btn-success"
            (click)="sendSelectedOrderIDsToBackend('DEL')"
          >
            Delivered
          </button>
          <button
            *ngIf="isKitchen()"
            [disabled]="!disableButton"
            type="button"
            class="btn btn-secondary"
            style="
              display: inline;
              float: right;
              margin-bottom: 5px;
              margin-right: 10px;
              background-color: red;
              border: none;
            "
            type="button"
            class="btn btn-secondary"
            (click)="sendSelectedOrderIDsToBackend('OFD')"
          >
            Out for delivery
          </button>
          <button
            *ngIf="isKitchen()"
            [disabled]="!disableButton"
            style="
              display: inline;
              float: right;
              margin-bottom: 5px;
              margin-right: 15px;
            "
            type="button"
            class="btn btn-primary"
            (click)="selectAllOrders()"
          >
            {{ selectAll }}
          </button>
          <!-- <button *ngIf="isKitchen() || isAdmin() || isSuperAdmin()" style="display: inline; float:right; margin-bottom: 5px; margin-right: 15px;" type="button" class="btn btn-primary" (click)="offersList()" >Offers</button> -->
        </div>

        <div class="col mt-2">
          <select
            id="addressFilter"
            class="form-select"
            (change)="filterOrders()"
          >
            <option value="">All Addresses</option>

        
            <option value="Medical college">
              Medical college
            </option>
            <option value="Pattom">Pattom</option>
            <option value="LMS">LMS</option>
            <option value="Statue">Statue</option>
            <option value="Ayurveda college">Ayurveda college</option>
            <option value="Nalanchira">Nalanchira</option>
            <option value="Civil Station">Civil Station</option>
            <option value="Karamana">Karamana</option>
            <option value="Vazhuthacaud">Vazhuthacaud</option>
            <option value="Jagathy">Jagathy</option>
            <option value="Thampanoor">Thampanoor</option>
            <option value="Poojapura">Poojapura</option>
          </select>
        </div>
        <div class="col mt-2">
          <select
            id="paymentStatus"
            class="form-select"
            (change)="filterOrders()"
          >
            <option value="">All Payment Status</option>
            <option value="NPD">Not Paid</option>
            <option value="PAID">Paid</option>
            <option value="FAIL">Payment failed</option>
            <option value="PEN">Payment pending</option>
            <option value="CON">Confirmed</option>
          </select>
        </div>
        <div class="col mt-2">
          <select
            id="paymentMethod"
            class="form-select"
            (change)="filterOrders()"
          >
            <option value="">All Payment Methods</option>
            <option value="COD">COD</option>
            <option value="UPI">UPI</option>
            <!-- <option value="TBD">TBD</option> -->
          </select>
        </div>
        <div class="col mt-2">
          <select
            id="orderStatus"
            class="form-select"
            (change)="filterOrders()"
          >
            <option value="">All Order Status</option>
            <option value="ACC">Accepted</option>
            <option value="DEL">Delivered</option>
            <option value="CAN">Cancelled</option>
            <option value="OFD">Out for delivery</option>
            <!-- <option value="PEN">Pending</option> -->
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col card">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th class="align-center" *ngIf="isKitchen()" scope="col">
                  Select
                </th>
                <th scope="col" class="align-center">OrderID</th>
                <th class="align-center" scope="col">User name</th>
                <th class="align-center" scope="col">Delivery address</th>
                <th class="align-center" scope="col">Payment Status</th>
                <th class="align-center" scope="col">Delivery Number</th>
                <th class="align-center" scope="col">Offer Amount</th>
                <th class="align-center" scope="col">Total Amount</th>
                <th class="align-center" scope="col">Order Status</th>
                <th class="align-center" scope="col">Show more</th>
              </tr>
            </thead>
            <tbody
              *ngFor="let orderDetails of filteredOrderDetails; let i = index"
            >
              <tr>
                <th scope="row">{{ i + 1 }}</th>
                <!-- <td *ngIf="isKitchen()" class="align-center">
                  <input
                    *ngIf="!isSelectedOrder(orderDetails.orderID)"
                    type="checkbox"
                    (change)="
                      toggleOrderSelection(orderDetails.orderID, $event)
                    "
                  />
                  <span
                    style="margin-left: 5px; color: blue; font-weight: bolder"
                    *ngIf="isSelectedOrder(orderDetails.orderID)"
                    class="tick-mark"
                    >✓</span
                  >
                </td> -->
                <td *ngIf="isKitchen()" class="align-center">
                  <input
                    type="checkbox"
                    [checked]="isSelectedOrder(orderDetails.orderID)"
                    (change)="
                      toggleOrderSelection(orderDetails.orderID, $event)
                    "
                  />
                </td>
                <td class="align-center">{{ orderDetails.orderID }}</td>
                <td class="align-center">
                  {{ orderDetails.userName }}
                </td>
                <td class="align-center">
                  {{ orderDetails.address.address }}
                </td>
                <td class="align-center">
                  {{ getPaymentDetails(orderDetails.paymentStatus) }}
                </td>
                <td class="align-center">
                  {{ getPaymentMethod(orderDetails.address.deliveryNumber) }}
                </td>

                <td class="align-center">{{ orderDetails.offerPrice }}</td>

                <td class="align-center">{{ orderDetails.totalPrice }}</td>
                <td class="align-center">
                  {{ getOrderStatus(orderDetails.orderStatus) }}
                </td>
                <td class="align-center">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="gotoOrderDescription(orderDetails._id)"
                  >
                    Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
