<app-kckdashboard></app-kckdashboard>

<div class="payouts-container">
  <h1>Kitchen Payouts Overview</h1>

  <div class="search-section">
    <div class="search-input-group">
      <mat-form-field appearance="outline">
        <mat-label>Search Kitchens</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Search by kitchen name, ID or legal entity...">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="searchKitchens()">
        Search
      </button>
    </div>

    <button mat-raised-button color="primary" (click)="resetSearch()">
      <mat-icon>refresh</mat-icon>
      Reset
    </button>
  </div>

  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Kitchen ID</th>
          <th>Kitchen Name</th>
          <th>Legal Entity</th>
          <th>Bank Details</th>
          <!-- <th class="amount-header">Pending Amount</th> -->
          <th>Last Payout</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let kitchen of kitchens">
          <td>{{kitchen.profileID}}</td>
          <td>{{kitchen.username}}</td>
          <td>{{kitchen.legalEntityName}}</td>
          <td>
            <div class="bank-details">
              <span>{{kitchen.bankDetails?.bankName}}</span>
              <small>A/C: {{kitchen.bankDetails?.accountNumber}}</small>
            </div>
          </td>
          <!-- <td class="amount">{{kitchen.pendingAmount | currency}}</td> -->
          <td>{{kitchen.lastPayout ? (kitchen.lastPayout | date) : 'No payouts yet'}}</td>
          <td>
            <span class="status-badge" [ngClass]="kitchen.active ? 'active' : 'inactive'">
              {{kitchen.active ? 'Active' : 'Inactive'}}
            </span>
          </td>
          <td class="action-column">
            <button mat-raised-button color="primary"
                    (click)="viewKitchenPayouts(kitchen._id)"
                    [disabled]="!kitchen.active">
              View Payouts
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
