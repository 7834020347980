import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { firstValueFrom } from 'rxjs';

const ADMIN_URL = environment.adminUrl;

interface PayoutData {
  _id: string;
  payoutId: string;
  sellerProfileId: string;
  amount: number;
  transferId: string;
  paymentStatus: 'NOT_ATTEMPTED' | 'PENDING' | 'SUCCESS' | 'FAILED';
  paymentMethod: 'bank' | 'upi';
  paymentDetails: {
    bankAccount: string;
    ifsc: string;
    vpa: string;
  };
  payoutDate: Date;
  utr: string | null;
  processedOn: Date | null;
  fromDate: Date;
  toDate: Date;
  orderType: 'regular' | 'subscription';
}

interface KitchenData {
  _id: string;
  profileID: string;
  username: string;
  mobile: string;
  bankDetails: {
    bankName: string;
    bankBranch: string;
    IFSCcode: string;
    accountNumber: string;
  };
  address: string;
  legalEntityName: string;
  GSTINnumber?: string;
  email?: string;
}

interface BeneficiaryResponse {
  success: boolean;
  data?: any;
  error?: string;
}

@Component({
  selector: 'app-kitchen-payout',
  templateUrl: './kitchen-payout.component.html',
  styleUrl: './kitchen-payout.component.css',
})
export class KitchenPayoutComponent implements OnInit {
  kitchenId: string = '';
  kitchenDetails: KitchenData | null = null;
  searchText: string = '';
  fromDate: Date | null = null;
  toDate: Date | null = null;

  // pendingPayouts: PayoutData[] = [];
  // completedPayouts: PayoutData[] = [];
  regularOrdersPendingPayouts: PayoutData[] = [];
  regularOrdersCompletedPayouts: PayoutData[] = [];
  subscriptionPendingPayouts: PayoutData[] = [];
  subscriptionCompletedPayouts: PayoutData[] = [];

  private checkedPendingTransfers = false;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.kitchenId = params['kitchenId'];
      if (this.kitchenId) {
        this.loadKitchenDetails();
        this.loadPayoutHistory();
      }
    });
  }

  loadKitchenDetails() {
    this.http
      .get<any>(`${ADMIN_URL}/payouts/kitchen/${this.kitchenId}`)
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.kitchenDetails = response.data;
            console.log('Kitchen details loaded:', this.kitchenDetails);
          }
        },
        error: (error) => {
          console.error('Error fetching kitchen details:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to load kitchen details',
          });
        },
      });
  }

  // loadPayoutData() {
  //   // Sample data - replace with actual API calls
  //   this.pendingPayouts = [
  //     {
  //       id: 1,
  //       fromDate: new Date('2024-03-01'),
  //       toDate: new Date('2024-03-15'),
  //       createDate: new Date(),
  //       amount: 1500.00,
  //       kitchenId: this.kitchenId,
  //       status: 'Pending'
  //     }
  //   ];

  //   this.completedPayouts = [
  //     {
  //       id: 3,
  //       fromDate: new Date('2024-02-15'),
  //       toDate: new Date('2024-02-28'),
  //       createDate: new Date(),
  //       transactionId: 'TRX003',
  //       amount: 3500.00,
  //       kitchenId: this.kitchenId,
  //       status: 'Completed'
  //     }
  //   ];
  // }

  resetFilters() {
    this.searchText = '';
    this.fromDate = null;
    this.toDate = null;
  }

  searchPayouts() {
    console.log('Searching with:', {
      searchText: this.searchText,
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
  }

  checkBeneficiary(payout: PayoutData) {
    const beneId = this.kitchenDetails?.profileID;
    if (!beneId) {
      console.error('No beneficiary ID available');
      return;
    }
    this.http
      .get<BeneficiaryResponse>(`${ADMIN_URL}/payouts/beneficiary/${beneId}`)
      .subscribe({
        next: (response: any) => {
          console.log(response, 'response');
          if (
            response.status === 'SUCCESS' &&
            response.data?.status === 'VERIFIED'
          ) {
            console.log('Beneficiary verified:', response.data);
            // Proceed with payment
            this.initiateTransfer(payout.payoutId);
          } else if (response.message === 'Beneficiary does not exist') {
            // First show the "does not exist" alert
            Swal.fire({
              icon: 'error',
              title: 'Beneficiary Not Found',
              text: 'This kitchen is not registered as a beneficiary.',
              confirmButtonText: 'Add Beneficiary',
            }).then((result) => {
              if (result.isConfirmed) {
                // Then show the details modal
                Swal.fire({
                  title: 'Add Beneficiary',
                  html: `
                    <div class="beneficiary-details">
                      <h3>Kitchen Details</h3>
                      <div class="detail-row">
                        <label>Profile ID:</label>
                        <span>${this.kitchenDetails?.profileID || 'N/A'}</span>
                      </div>
                      <div class="detail-row">
                        <label>Username:</label>
                        <span>${this.kitchenDetails?.username || 'N/A'}</span>
                      </div>
                      <div class="detail-row">
                        <label>Mobile:</label>
                        <span>${this.kitchenDetails?.mobile || 'N/A'}</span>
                      </div>
                      <div class="detail-row">
                        <label>Legal Entity Name:</label>
                        <span>${
                          this.kitchenDetails?.legalEntityName || 'N/A'
                        }</span>
                      </div>
                      <div class="detail-row">
                        <label>Bank Name:</label>
                        <span>${
                          this.kitchenDetails?.bankDetails?.bankName || 'N/A'
                        }</span>
                      </div>
                      <div class="detail-row">
                        <label>Bank Branch:</label>
                        <span>${
                          this.kitchenDetails?.bankDetails?.bankBranch || 'N/A'
                        }</span>
                      </div>
                      <div class="detail-row">
                        <label>IFSC Code:</label>
                        <span>${
                          this.kitchenDetails?.bankDetails?.IFSCcode || 'N/A'
                        }</span>
                      </div>
                      <div class="detail-row">
                        <label>Account Number:</label>
                        <span>${
                          this.kitchenDetails?.bankDetails?.accountNumber ||
                          'N/A'
                        }</span>
                      </div>
                      <div class="detail-row">
                        <label>Address:</label>
                        <span>${this.kitchenDetails?.address || 'N/A'}</span>
                      </div>
                      <div class="detail-row">
                        <label>GSTIN:</label>
                        <span>${
                          this.kitchenDetails?.GSTINnumber || 'N/A'
                        }</span>
                      </div>
                    </div>
                  `,
                  confirmButtonText: 'Confirm & Add',
                  showCancelButton: true,
                  cancelButtonText: 'Cancel',
                  customClass: {
                    container: 'beneficiary-modal',
                    popup: 'beneficiary-popup',
                    htmlContainer: 'beneficiary-content',
                  },
                  width: '600px',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.addBeneficiary(this.kitchenDetails!);
                  }
                });
              }
            });
          } else {
            console.error('Beneficiary verification failed:', response.error);
            Swal.fire({
              icon: 'error',
              title: 'Verification Failed',
              text: 'Failed to verify beneficiary status',
            });
          }
        },
        error: (error) => {
          console.error('Error checking beneficiary:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to check beneficiary status',
          });
        },
      });
  }

  addBeneficiary(kitchenDetails: KitchenData) {
    // Call the correct API endpoint with kitchen ID
    this.http
      .post<any>(
        `${ADMIN_URL}/payouts/addBeneficiary/${kitchenDetails._id}`,
        {}
      )
      .subscribe({
        next: (response) => {
          console.log(response, 'response');
          if (response.success) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Beneficiary added successfully',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.message || 'Failed to add beneficiary',
            });
          }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.error?.message || 'Failed to add beneficiary',
          });
          console.error('Error adding beneficiary:', error);
        },
      });
  }

  generateRegularPayouts() {
    if (!this.fromDate || !this.toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Date Required',
        text: 'Please select both From Date and To Date',
      });
      return;
    }

    const params = {
      startDate: this.fromDate.toISOString(),
      endDate: this.toDate.toISOString(),
    };

    Swal.fire({
      title: 'Generating Regular Order Payouts',
      text: 'Please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    firstValueFrom(
      this.http.get<any>(
        `${ADMIN_URL}/payouts/kitchen/${this.kitchenId}/payout`,
        { params }
      )
    )
      .then((response) => {
        Swal.close();
        if (response?.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Regular order payouts generated successfully',
          }).then(() => {
            this.loadPayoutHistory();
          });
        } else if (response?.message === "Total payout is 0 or less, no payout record created.") {
          Swal.fire({
            icon: 'info',
            title: 'Info',
            text: 'Total payout is 0 or less, no payout record created.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to generate regular order payouts',
          });
        }
      })
      .catch((error) => {
        console.error('Error generating regular payouts:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to generate regular order payouts',
        });
      });
  }

  generateSubscriptionPayouts() {
    if (!this.fromDate || !this.toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Date Required',
        text: 'Please select both From Date and To Date',
      });
      return;
    }

    const params = {
      startDate: this.fromDate.toISOString(),
      endDate: this.toDate.toISOString(),
    };

    Swal.fire({
      title: 'Generating Subscription Payouts',
      text: 'Please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    firstValueFrom(
      this.http.get<any>(
        `${ADMIN_URL}/payouts/subscription/${this.kitchenId}/payout`,
        { params }
      )
    )
      .then((response) => {
        Swal.close();
        if (response?.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Subscription payouts generated successfully',
          }).then(() => {
            this.loadPayoutHistory();
          });
        } else if (response?.message === "Total payout is 0 or less, no payout record created.") {
          Swal.fire({
            icon: 'info',
            title: 'Info',
            text: 'Total payout is 0 or less, no payout record created.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to generate subscription payouts',
          });
        }
      })
      .catch((error) => {
        console.error('Error generating subscription payouts:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to generate subscription payouts',
        });
      });
  }

  private async checkPendingTransfers(payouts: PayoutData[]): Promise<void> {
    // if (this.isCheckingPendingTransfers) return;

    const pendingPayouts = payouts.filter(
      (p: PayoutData) => p.paymentStatus === 'PENDING' && p.transferId
    );
    console.log(pendingPayouts, 'pendingPayouts');

    if (pendingPayouts.length > 0) {
      this.checkedPendingTransfers = true;
      try {
        await Promise.all(
          pendingPayouts.map((payout) =>
            firstValueFrom(
              this.http.get<any>(
                `${ADMIN_URL}/payouts/transfer/${payout.transferId}`
              )
            )
          )
        );
        // Reload payout history after checking transfers
        this.loadPayoutHistory();
      } catch (error) {
        console.error('Error checking pending transfers:', error);
      }
    }
  }

  async refreshStatus(payout: PayoutData): Promise<void> {
    if (payout.paymentStatus !== 'PENDING' || !payout.transferId) {
      console.error('This payout cannot be refreshed:', payout);
      return;
    }

    try {
      const response = await firstValueFrom(
        this.http.get<any>(
          `${ADMIN_URL}/payouts/transfer/${payout.transferId}`
        )
      );
      console.log('Transfer status refreshed:', response);
      this.loadPayoutHistory();
      // Optionally, update the payout status in the UI or database based on the response
    } catch (error) {
      console.error('Error refreshing transfer status:', error);
    }
  }

  loadPayoutHistory() {
    // const params: any = {};
    // if (this.fromDate) params.startDate = this.fromDate.toISOString();
    // if (this.toDate) params.endDate = this.toDate.toISOString();

    this.http
      .get<any>(`${ADMIN_URL}/payouts/history/${this.kitchenId}`)
      .subscribe({
        next: async (response) => {
          if (response.success) {
            // Split payouts into regular and subscription, and then into pending and completed
            const payouts = response.data.payouts;
            // Check pending transfers first
            if (!this.checkedPendingTransfers) {
              await this.checkPendingTransfers(payouts);
            }
            // Regular orders
            this.regularOrdersPendingPayouts = payouts.filter(
              (p: PayoutData) =>
                p.orderType === 'regular' &&
                ['NOT_ATTEMPTED', 'PENDING', 'FAILED'].includes(p.paymentStatus)
            );

            this.regularOrdersCompletedPayouts = payouts.filter(
              (p: PayoutData) =>
                p.orderType === 'regular' && p.paymentStatus === 'SUCCESS'
            );

            // Subscription orders
            this.subscriptionPendingPayouts = payouts.filter(
              (p: PayoutData) =>
                p.orderType === 'subscription' &&
                ['NOT_ATTEMPTED', 'PENDING', 'FAILED'].includes(p.paymentStatus)
            );

            this.subscriptionCompletedPayouts = payouts.filter(
              (p: PayoutData) =>
                p.orderType === 'subscription' && p.paymentStatus === 'SUCCESS'
            );
          }
        },
        error: (error) => {
          console.error('Error fetching payout history:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to load payout history',
          });
        },
      });
  }

  // New method to handle transfer
  private initiateTransfer(payoutId: string) {
    Swal.fire({
      title: 'Confirm Transfer',
      text: 'Are you sure you want to initiate this transfer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.http
          .post<any>(`${ADMIN_URL}/payouts/transfer/async/${payoutId}`, {})
          .subscribe({
            next: (response) => {
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  title: 'Transfer Initiated',
                  text: response.message,
                });
                this.loadPayoutHistory();
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Transfer Failed',
                  text: response.error,
                });
              }
            },
            error: (error) => {
              let errorMessage = 'Failed to initiate transfer';
              if (
                error.error?.error ===
                'Another transfer is already in progress for this seller'
              ) {
                errorMessage = `Another transfer is in progress. Pending Payout ID: ${error.error.pendingPayoutId}`;
              }
              Swal.fire({
                icon: 'error',
                title: 'Transfer Failed',
                text: errorMessage,
              });
            },
          });
      }
    });
  }
}
