import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { SubscriptionService } from './subscription.service';

const ADMIN_URL = environment.adminUrl;
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrl: './subscription-page.component.css',
})
export class SubscriptionPageComponent implements OnInit {
  //variable declaration
  subscriptionDetails: any;
  filteredSubscriptionDetails: any;
  subscriptionCounts: any;
  subscriptionTotalCount: number = 0;
  subscriptionActiveCount: number = 0;
  // subscriptionInactiveCount: number = 0;
  isActiveSubscriptions: boolean = false;
  isInactiveSubscriptions: boolean = false;
  isExpiredSubscriptions: boolean = false;
 
  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.checkLogin();
  }

  //to check if the user is logged in
  checkLogin(): void {
    const isLoggedIn = this.authService.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
      if (this.isSuperAdmin() || this.isAdmin()) {
        this.getSubscriptionCounts();
        this.getSubscriptions();
      } else if (this.isKitchen()) {
        this.getSubscriptionCountsForKitchen();
        this.loadActiveSubscriptions(); // Changed from getSubscriptionsForKitchen()
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  //to check the user is admin
  isAdmin(): boolean {
    return this.authService.isAuthenticated() && this.authService.isAdmin();
  }

  //to check the user is superAdmin
  isSuperAdmin(): boolean {
    return (
      this.authService.isAuthenticated() && this.authService.isSuperAdmin()
    );
  }

  //to check the user is kitchen
  isKitchen(): boolean {
    return this.authService.isAuthenticated() && this.authService.isKitchen();
  }

  // to get the subscription details for superadmin/ admin
  getSubscriptions() {
    this.subscriptionService.getSubscriptionsListing().subscribe(
      (data) => {
        this.subscriptionDetails = data;
        this.filterOrders();
        console.log('the subscription details is:', this.filteredSubscriptionDetails);
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }

  // to get the subscription count for superadmin/ admin
  getSubscriptionCounts() {
    this.subscriptionService.getSubscriptionCounts().subscribe(
      (data) => {
        this.subscriptionCounts = data;
        this.subscriptionTotalCount =
          this.subscriptionCounts.subscriptionTotalCount;
        this.subscriptionActiveCount =
          this.subscriptionCounts.subscriptionActiveCount;
        // this.subscriptionInactiveCount =
        //   this.subscriptionCounts.subscriptionInactiveCount;
        console.log('Subscription count:', this.subscriptionCounts);
        console.log('subscriptionTotalCount:', this.subscriptionTotalCount);
        console.log('subscriptionActiveCount:', this.subscriptionActiveCount);
        // console.log(
        //   'subscriptionInactiveCount:',
        //   this.subscriptionInactiveCount
        // );
      },
      (error) => {
        console.log('Error fetching subscription counts:', error);
      }
    );
  }

  // to get the subscription details if logged in as kitchen
  getSubscriptionsForKitchen() {
    this.subscriptionService.getSubscriptionsListingForKitchen().subscribe(
      (data) => {
        this.subscriptionDetails = data;
        this.filterOrders();
        console.log('the subscription details is:', this.subscriptionDetails);
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }

  getInactiveSubscriptionsForKitchen() {
    this.subscriptionService.getInactiveSubscriptionsListingForKitchen().subscribe(
      (data) => {
        this.subscriptionDetails = data;
        this.filterOrders();
        console.log('the subscription details is:', this.subscriptionDetails);
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }
  getExpiredSubscriptionsForKitchen() {
    this.subscriptionService.getExpiredSubscriptionsListingForKitchen().subscribe(
      (data) => {
        this.subscriptionDetails = data;
        this.filterOrders();
        console.log('the subscription details is:', this.subscriptionDetails);
      },
      (error) => {
        console.log('Error fetching subscriptions:', error);
      }
    );
  }

  // to get the subscription count if loged in as kitchen
  getSubscriptionCountsForKitchen() {
    this.subscriptionService.getSubscriptionCountsForKitchen().subscribe(
      (data) => {
        this.subscriptionCounts = data;
        this.subscriptionTotalCount =
          this.subscriptionCounts.subscriptionTotalCount;
        this.subscriptionActiveCount =
          this.subscriptionCounts.subscriptionActiveCount;
        // this.subscriptionInactiveCount =
        //   this.subscriptionCounts.subscriptionInactiveCount;
        console.log('Subscription count:', this.subscriptionCounts);
        console.log('subscriptionTotalCount:', this.subscriptionTotalCount);
        console.log('subscriptionActiveCount:', this.subscriptionActiveCount);
        // console.log(
        //   'subscriptionInactiveCount:',
        //   this.subscriptionInactiveCount
        // );
      },
      (error) => {
        console.log('Error fetching subscription counts:', error);
      }
    );
  }

  // to go subcription details page
  goToSubscriptionDetails(id: string) {
    this.router.navigate(['/subscriptionDetails'], {
      queryParams: {
        dateBaseID: id,
      },
    });
  }

  loadActiveSubscriptions(): void {
    this.isActiveSubscriptions = true;
    this.isInactiveSubscriptions = false;
    this.isExpiredSubscriptions = false;
    this.getSubscriptionsForKitchen();
  }

  loadInactiveSubscriptions(): void {
    this.isActiveSubscriptions = false;
    this.isInactiveSubscriptions = true;
    this.isExpiredSubscriptions = false;
    if (this.isKitchen()) {
      this.getInactiveSubscriptionsForKitchen();
    } else {
      // For admin and superadmin, we'll use the existing data and filter it
      this.filterOrders();
    }
  }
  loadExpiredSubscriptions(): void {
    this.isActiveSubscriptions = false;
    this.isInactiveSubscriptions = false;
    this.isExpiredSubscriptions = true;

    if (this.isKitchen()) {
      this.getExpiredSubscriptionsForKitchen();
    } else {
      // For admin and superadmin, we'll use the existing data and filter it
      this.filterOrders();
    }
  }

  // Update filterOrders to handle both active and inactive subscriptions
  filterOrders(): void {
    this.filteredSubscriptionDetails = this.subscriptionDetails.filter(
      (order) => order.subscriptionActive === this.isActiveSubscriptions
    );
  }

  deactivateSubscription(subscriptionId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "Once deactivated, this subscription can be viewed in Inactive Subscriptions and you need to manually activate it if required!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, deactivate it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.subscriptionService.updateSubscriptionStatus(subscriptionId, false).subscribe(
          (response) => {
            console.log('Subscription deactivated:', response);
            Swal.fire(
              'Deactivated!',
              'The subscription has been deactivated.',
              'success'
            );
            this.loadInactiveSubscriptions();
          },
          (error) => {
            console.error('Error deactivating subscription:', error);
            Swal.fire(
              'Error!',
              'There was an error deactivating the subscription.',
              'error'
            );
          }
        );
      }
    });
  }

  activateSubscription(subscriptionId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "Activate the subscription?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, activate it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.subscriptionService.updateSubscriptionStatus(subscriptionId, true).subscribe(
          (response) => {
            console.log('Subscription activated:', response);
            Swal.fire(
              'Activated!',
              'The subscription has been activated.',
              'success'
            );
            this.loadActiveSubscriptions();
          },
          (error) => {
            console.error('Error activating subscription:', error);
            Swal.fire(
              'Error!',
              'There was an error activating the subscription.',
              'error'
            );
          }
        );
      }
    });
  }
}
