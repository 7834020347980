<div class="sidenav">
  <div class="container">
    <div class="row">
      <div class="col" style="text-align: center;">
        <img class="logoImage" src="../../assets/logos/pocketmart-011.png" alt="">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p *ngIf="isAdmin()" style="color: white; text-align: center;">Admin</p>
        <p *ngIf="isKitchen()" style="color: white; text-align: center;">Kitchen</p>
        <p *ngIf="isSuperAdmin()" style="color: white; text-align: center;">Super admin</p>
      </div>
    </div>
  </div>
  <div class="cotainer">
    <!-- <a *ngIf="isAdmin() || isSuperAdmin()" (click)="gotokitchen()">Lunch bells</a>
    <a *ngIf="isKitchen()" (click)="gotoMyprofile()">My profile</a>
    <a  (click)="gotoOrderDetails()">Orders</a>
    <a *ngIf="isAdmin() || isSuperAdmin()" (click)="gotouserdetails()">User details</a>
    <a (click)="gotoAnalytics()">Analytics</a>
    <a *ngIf="isSuperAdmin()" (click)="goToSignup()">Add user</a>
    <a (click)="gotoChangePassword()">Change password</a>
    <a *ngIf="isSuperAdmin()" (click)="goToAccountDetailsPage()">Account details</a>
     -->
     <button *ngIf="isKitchen()" type="button" class="btn btn-outline-light custom-button" (click)="gotoMyprofile()">My profile</button>    
    
     <button type="button" class="btn btn-outline-light custom-button" (click)="gotoChangePassword()">Change password</button>
     <button *ngIf="isSuperAdmin()" type="button" class="btn btn-outline-light custom-button" (click)="goToAccountDetailsPage()">Account details</button>
     <button *ngIf="isAdmin() || isSuperAdmin()" type="button" class="btn btn-outline-light custom-button" (click)="gotouserdetails()">User details</button>
     <button *ngIf="isSuperAdmin()" type="button" class="btn btn-outline-light custom-button" (click)="goToSignup()">Add user</button>
     <button *ngIf="isAdmin() || isSuperAdmin()" type="button" class="btn btn-outline-light custom-button" (click)="gotokitchen()">Lunch bells</button>
     <button button *ngIf="isKitchen()" type="button" class="btn btn-outline-light custom-button" (click)="gotoOrderDetails()">Daily orders</button>

     <button button *ngIf="isKitchen()" type="button" class="btn btn-outline-light custom-button" (click)="goToSubscriptionOrders()">Subscription orders</button>
     <button button *ngIf="isKitchen()" type="button" class="btn btn-outline-light custom-button" (click)="goToSubscriptionPage()">Subscription details</button>

     <button type="button" class="btn btn-outline-light custom-button" (click)="gotoAnalytics()">Analytics</button>
     <!-- <button *ngIf="isAdmin() || isSuperAdmin() || isKitchen()" type="button" class="btn btn-outline-light custom-button" (click)="addDeliveryPerson()">Add Delivery Person</button> -->
     
     <button type="button" *ngIf="isAdmin() || isSuperAdmin()" class="btn btn-outline-light custom-button" (click)="gotoNotification()">Notification</button>
     <button *ngIf="isAdmin() || isSuperAdmin()" type="button" class="btn btn-outline-light custom-button" (click)="goToPayoutsPage()">Payouts</button>
     <button *ngIf="isSuperAdmin()" type="button" class="btn btn-outline-light custom-button" (click)="goToSettingsPage()">Settings</button>
    <button
      type="button"
      class="btn btn-outline-light custom-button"
      (click)="onLogout()"
    >
      Logout
    </button>
  </div>
</div>
