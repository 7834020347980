import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { EditServiceComponent } from './components/edit-service/edit-service.component';
import { SellerPostingsComponent } from './components/seller-postings/seller-postings.component';
import { SellerDetailsComponent } from './components/seller-details/seller-details.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { SearchSellerComponent } from './components/search-seller/search-seller.component';
import { CloudKitchenComponent } from './components/cloud-kitchen/cloud-kitchen.component';
import { CloudKitchenDetailsComponent } from './components/cloud-kitchen-details/cloud-kitchen-details.component';
import { KckdashboardComponent } from './kckdashboard/kckdashboard.component';
import { KckanalyticsComponent } from './kckanalytics/kckanalytics.component';
import { UserdetailsComponent } from './userdetails/userdetails.component';
import { KitchenOrderDetailsComponent } from './kitchen-order-details/kitchen-order-details.component';
import { OrderDescriptionComponent } from './order-description/order-description.component';
import { CentralkitchenComponent } from './centralkitchen/centralkitchen.component';
import { KitchendetailsComponent } from './kitchendetails/kitchendetails.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { AuthGuard } from './auth.guard';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AddDeliveryPersonComponent } from './add-delivery-person/add-delivery-person.component';
import { NotificationComponent } from './notification/notification.component';
import { SubscriptionPageComponent } from './subscription-page/subscription-page.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { ViewDeliveryPersonsComponent } from './view-delivery-persons/view-delivery-persons.component';
import { SubscriptionOrdersComponent } from './subscription-orders/subscription-orders.component';
import { CreateKitchenComponent } from './create-kitchen/create-kitchen.component';

import { SettingsComponent } from './settings/settings.component';
import { PayoutsComponent } from './payouts/payouts.component';
import { KitchenPayoutComponent } from './kitchen-payout/kitchen-payout.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'changePasswordPage', component: ChangePasswordComponent },
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: ProductsListComponent },
  { path: 'edit-product', component: EditProductComponent },
  { path: 'edit-service', component: EditServiceComponent },
  { path: 'seller-postings', component: SellerPostingsComponent },
  { path: 'seller-details', component: SellerDetailsComponent },
  { path: 'analytics', component: AnalyticsComponent },
  { path: 'search-seller', component: SearchSellerComponent },
  { path: 'cloud-kitchen', component: CloudKitchenComponent },
  { path: 'cloud-kitchen-details', component: CloudKitchenDetailsComponent },
  {
    path: 'kckdashboard',
    component: KckdashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'Kckanalytics',
    component: KckanalyticsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'userdetails',
    component: UserdetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kitchen-order-details',
    component: KitchenOrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kitchen-order-description',
    component: OrderDescriptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'centralkitchen',
    component: CentralkitchenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kitchendetails',
    component: KitchendetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'passwordChange',
    component: PasswordChangeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-details',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-delivery-person',
    component: AddDeliveryPersonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'view-delivery-persons',
    component: ViewDeliveryPersonsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-page',
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptionPage',
    component: SubscriptionPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptionDetails',
    component: SubscriptionDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptionOrders',
    component: SubscriptionOrdersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-kitchen',
    component: CreateKitchenComponent,
    canActivate: [AuthGuard],
  },
  {
path:'settings',
component: SettingsComponent,
canActivate: [AuthGuard],
  },
  {
    path: 'payouts',
    component: PayoutsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kitchen-payout',
    component: KitchenPayoutComponent,
    canActivate: [AuthGuard],
  },

  { path: '', redirectTo: '/login', pathMatch: 'full' }, // Default route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
